.portfolio-card {
  margin: 0.5em;
  border: solid #333333 0.1em;
  border-radius: 0.2em;
  display: inline-block;

  padding: 0.3em 2.5em;
  background-color: rgba(245, 245, 245, 0.75);
  width: 350px;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    width: 75vw;
    height: auto;
  }
  * {
    align-self: center;
    justify-self: flex-start;
  }
  h3 {
    @media screen and (max-width: 450px) {
      margin: 0.1rem;
      font-size: 90%;
    }
  }
  .description {
    text-decoration: none;
    margin: 0.8rem 0;

    margin: 0.2rem;
    p {
      height: 5em;
      margin: 0.2rem;
      @media screen and (max-width: 450px) {
        font-size: 60%;
      }
    }
  }

  .portfolio-image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
    @media screen and (max-width: 450px) {
      max-width: 40vw;
      max-height: 40vw;
      margin-bottom: 2px;
      margin-top: auto;
      overflow-y: hidden;
    }
  }
}

.card-link {
  text-decoration: none;
  margin: 0;
  padding: 0;
}
