.AboutMe {
  #coolOrigami {
    display: inline-flex;
    flex-wrap: wrap;

    justify-content: space-evenly;
    align-items: flex-start;
  }

  div.photoBox img {
    /* min-width: 20%; */
    width: 200px;
    padding: 10px;
    height: 200px;
    object-fit: cover;
  }
  .photoBox {
    width: 300px; /* Set width of cards */
    border: 1px solid rgb(197, 197, 197); /* Set up Border */
    border-radius: 4px; /* Slightly Curve edges */
    overflow: hidden; /*Fixes the corners */
    display: flex; /* Children use Flexbox */
    flex-direction: column; /* Rotate Axis */
    height: 300px;
    background: rgb(229, 234, 236);
    margin: 5px;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: #f4f9fb;
    color: #332c23;
    border: 3px solid #f4f9fb;
    border-radius: 3px;
    font-size: 1.3em;
    padding: 1px;
    font-family: "Maitree", serif;
    margin: auto;
  }

  button:hover {
    animation: hover-text 0.15s ease-out 0s 1 both;
  }
  p {
    text-align: justify;
    line-height: 1.5em;
  }
  @keyframes hover-text {
    100% {
      transform: scale(1.01);
      background-color: #88527f;
      color: #f4f9fb;
      padding: 1px;
    }
  }
}
